// Override default variables before the import
// $body-bg: #000;

// .grecaptcha-badge {
//   visibility: hidden;
// }

.auth-inner {
  //   width: 400px;
  // margin: auto;
  background: #ffffff;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
  // padding: 40px 55px 45px 55px;
  border-radius: 15px;
  transition: all 0.3s;
}

.inverted-logo {
  //   width: 400px;
  filter: invert(100%);
}
// body,
// html {
//   height: 100%;
//   width: 100%;
//   margin: 0;
//   padding: 0;
// }
// .full-screen-div {
//   height: 100vh;
//   min-height: 100%;
//   //   box-sizing: border-box;
//   //   overflow-x: hidden;
//   //   overflow-y: hidden;
// }

// body,
// html {
//   height: 100%;
//   width: 100%;
//   margin: 0;
// }
// .full-screen-div {
//   width: 100vw;
//   height: 100%;
//   min-height: 100%;
//   padding: 0;
//   box-sizing: border-box;
//   overflow-x: hidden;
//   overflow-y: hidden;
// }

html,
body {
  height: 100%;
}
#root {
  height: 100%;
}
.fill {
  min-height: 100%;
  height: 100%;
  overflow-y: hidden;
}

.download-backups-list {
  max-height: 300px;
  margin-bottom: 10px;
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
}

.grecaptcha-badge {
  visibility: hidden;
}

.horizontal-center-img {
  //   width: 400px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding: 0.5em;
}

$theme-colors: (
  "primary": #3b1f91,
);

$breadcrumb-divider: quote(">");

// Import Bootstrap and its default variables
@import "../node_modules/bootstrap/scss/bootstrap";
@import "~react-pro-sidebar/dist/scss/styles.scss";
